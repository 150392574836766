@tailwind base;
@tailwind components;
@tailwind utilities;
/*fonts*/
@font-face {
  font-family: "avenir-black";
  src: url("../public/fonts/avenir_georgian/AvenirNextGeorgian-Black.otf");
  font-display: swap;
}
@font-face {
  font-family: "avenir-bold";
  src: url("../public/fonts/avenir_georgian/AvenirNextGeorgian-Bold.otf");
  font-display: swap;
}
@font-face {
  font-family: "avenir-demi";
  src: url("../public/fonts/avenir_georgian/AvenirNextGeorgian-Demi.otf");
  font-display: swap;
}
@font-face {
  font-family: "avenir-heavy";
  src: url("../public/fonts/avenir_georgian/AvenirNextGeorgian-Heavy.otf");
  font-display: swap;
}
@font-face {
  font-family: "avenir-light";
  src: url("../public/fonts/avenir_georgian/AvenirNextGeorgian-Light.otf");
  font-display: swap;
}
@font-face {
  font-family: "avenir-medium";
  src: url("../public/fonts/avenir_georgian/AvenirNextGeorgian-Medium.otf");
  font-display: swap;
}
@font-face {
  font-family: "avenir-regular";
  src: url("../public/fonts/avenir_georgian/AvenirNextGeorgian-Regular.otf");
  font-display: swap;
}
@font-face {
  font-family: "avenir-thin";
  src: url("../public/fonts/avenir_georgian/AvenirNextGeorgian-Thin.otf");
  font-display: swap;
}
@font-face {
  font-family: "avenir-UltLt";
  src: url("../public/fonts/avenir_georgian/AvenirNextGeorgian-UltLt.otf");
  font-display: swap;
}
@font-face {
  font-family: "avenir-XtraBd";
  src: url("../public/fonts/avenir_georgian/AvenirNextGeorgian-XtraBd.otf");
  font-display: swap;
}
@font-face {
  font-family: "mzeqala-regular";
  src: url("../public/fonts/mzeqala-regular.ttf");
  font-display: swap;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
}

#root {
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.container {
  max-width: 1530px;
  width: calc(100% - 96px);
  margin: auto;
}

.languagesShadow {
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
}

.svg-filter svg {
  filter: invert(52%) sepia(82%) saturate(429%) hue-rotate(94deg)
    brightness(89%) contrast(85%);
}

.vtbtn:hover .svgfilter {
  filter: invert(52%) sepia(82%) saturate(429%) hue-rotate(94deg)
    brightness(89%) contrast(85%);
}

.vtbtn:hover .svgfiltervol2 {
  filter: invert(39%) sepia(13%) saturate(6839%) hue-rotate(203deg)
    brightness(94%) contrast(94%);
}

.usersvg {
  transition: 0.2s;
}

.usersvg:hover svg,
.useractiveSvg {
  filter: invert(52%) sepia(20%) saturate(1618%) hue-rotate(94deg)
    brightness(101%) contrast(84%);
  transition: 0.2s;
}

.swiperButtonShadow {
  box-shadow: 0px 0px 10px 0px rgba(56, 56, 56, 0.1);
}

.preventImgSelect {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.MuiFormControlLabel-root {
  margin-left: 0px !important;
}

.custom-dashed-border {
  background-image: repeating-linear-gradient(
      -48deg,
      #bfcef6,
      #bfcef6 8px,
      transparent 8px,
      transparent 14px,
      #bfcef6 14px
    ),
    repeating-linear-gradient(
      42deg,
      #bfcef6,
      #bfcef6 8px,
      transparent 8px,
      transparent 14px,
      #bfcef6 14px
    ),
    repeating-linear-gradient(
      132deg,
      #bfcef6,
      #bfcef6 8px,
      transparent 8px,
      transparent 14px,
      #bfcef6 14px
    ),
    repeating-linear-gradient(
      222deg,
      #bfcef6,
      #bfcef6 8px,
      transparent 8px,
      transparent 14px,
      #bfcef6 14px
    );
  background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
}

.custom-dashed-border-gray {
  background-image: repeating-linear-gradient(
      0deg,
      #ceced0,
      #ceced0 7px,
      transparent 7px,
      transparent 15px,
      #ceced0 15px
    ),
    repeating-linear-gradient(
      90deg,
      #ceced0,
      #ceced0 7px,
      transparent 7px,
      transparent 15px,
      #ceced0 15px
    ),
    repeating-linear-gradient(
      180deg,
      #ceced0,
      #ceced0 7px,
      transparent 7px,
      transparent 15px,
      #ceced0 15px
    ),
    repeating-linear-gradient(
      270deg,
      #ceced0,
      #ceced0 7px,
      transparent 7px,
      transparent 15px,
      #ceced0 15px
    );
  background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
}

.warningBox {
  background-image: repeating-linear-gradient(
      0deg,
      #ef357d,
      #ef357d 9px,
      transparent 9px,
      transparent 19px,
      #ef357d 19px
    ),
    repeating-linear-gradient(
      90deg,
      #ef357d,
      #ef357d 9px,
      transparent 9px,
      transparent 19px,
      #ef357d 19px
    ),
    repeating-linear-gradient(
      180deg,
      #ef357d,
      #ef357d 9px,
      transparent 9px,
      transparent 19px,
      #ef357d 19px
    ),
    repeating-linear-gradient(
      270deg,
      #ef357d,
      #ef357d 9px,
      transparent 9px,
      transparent 19px,
      #ef357d 19px
    );
  background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
}

.customsvgcheck svg {
  width: 32px;
  height: 32px;
}

.MuiFormControlLabel-label {
  font-size: 14px !important;
  font-family: "avenir-medium";
  margin-left: 0px;
}

.swiper-slide-thumb-active {
  border: 1px solid #fff;
}

.selector {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.hidescroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.someinput .MuiSelect-select {
  margin-top: -8px !important;
}

/* width */
.customScrolllbar::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.customScrolllbar::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.customScrolllbar::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.customScrolllbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.cls .MuiInputLabel-formControl {
  font-family: "avenir-demi" !important;
}

.aboutuscomponent {
  background: linear-gradient(
    84deg,
    #091e53,
    33.25%,
    rgba(37, 45, 67, 0.1) 125.33%
  );
}

.swiper-slide {
  height: auto;
}

.shadowofcontactbtn {
  box-shadow: 0px 0px 20px 0px rgba(56, 56, 56, 0.1);
}

.hoverforsvg {
  transition: 0.2s;
}

.defaultHoverForSvg {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(248deg)
    brightness(137%) contrast(134%);
}

.svgfilterhover:hover .hoverforsvg {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(248deg)
    brightness(137%) contrast(134%);
  transition: 0.2s;
}

.activeArrow {
  transition: 0.3s;
  transform: rotate(180deg);
}

.activeArrow svg {
  filter: invert(51%) sepia(25%) saturate(1310%) hue-rotate(94deg)
    brightness(102%) contrast(82%);
}

.activeSocialIcon {
  transition: 0.3s;
}

.activeSocialIcon:hover svg {
  filter: invert(52%) sepia(66%) saturate(496%) hue-rotate(94deg)
    brightness(92%) contrast(91%);
  transition: 0.3s;
}

.cardsToolShadow {
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
}

.search-modal {
  animation: searchModal 0.5s;
}

.transition05 {
  transition: .05s linear all;
}

.transition1 {
  transition: .1s linear all;
}

.transition2 {
  transition: .2s linear all;
}

.transition3 {
  transition: .3s linear all;
}

.placeholdersm label{
  font-size: 14px !important;
  color: #070A1499;
}
@keyframes searchModal {
  0% {
    height: 0;
    opacity: 0;
  }
  100% {
    height: auto;
    opacity: 1;
  }
}

@media screen and (max-width: 1280px) {
  .container {
    max-width: calc(100% - 80px);
    width: 100%;
  }
}

@media screen and (max-width: 1080px) {
  .container {
    max-width: calc(100% - 64px);
    width: 100%;
  }
}

@media screen and (max-width: 640px) {
  .container {
    max-width: calc(100% - 32px);
    width: 100%;
  }
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

/* Hide scrollbar for IE, Edge, and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}